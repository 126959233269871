@import '~styles/variables';

.status {
	text-align: center;

	&__parts {
		border: 3px solid #3a3a3a;
		border-radius: 2rem;
		padding: 1rem 2rem;
		margin: auto;
		text-align: left;

		h2 {
			margin-top: 0.5em;
		}

		ul {
			list-style: none;
			padding-left: 0;
			font-size: 1.3rem;
			font-weight: 600;
			li {
				margin-top: 0.5em;
				margin-bottom: 0.5em;
			}

			.icon {
				margin-right: 0.25em;
			}

			.fa-check-circle {
				color: green;
			}

			.fa-times-circle {
				color: red;
			}

			.subpart {
				margin-left: 1.5em;
				font-size: 0.85em;
			}
		}
	}
}
