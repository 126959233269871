footer {
	.footer__content {
		line-height: 1.4;
	}
}

.footer__bg {
	grid-area: fbg;
	grid-column: fbg / fbg_end;
	background-color: #f1f1f1;
}
