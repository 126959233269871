@import '~styles/variables.less';

.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;

	img {
		width: 2.5rem;
	}

	.button {
		font-size: 0.5rem;
	}
}
