@import '~styles/variables';

.home {
	p {
		text-align: left;
	}

	.error {
		color: red;
	}
}
