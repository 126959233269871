@import '~normalize.css';
@import './variables.less';

* {
	box-sizing: border-box;
}

html,
body {
	height: 100%;
	overflow-y: overlay;
	text-align: center;

	font-size: 19px;
	.tablet({
		font-size: 16px;
	});
}

p {
	line-height: 1.333;
}

article {
	margin: 2rem auto;
	text-align: left;
	p {
		margin-bottom: 1.75rem;
		line-height: 1.4;
	}
}

body {
	font-family: Source Sans Pro, sans-serif;
	color: @main-color;
	background-color: @color-background;
}

a {
	color: @color-main;
	font-weight: 600;
}

input {
	width: 80%;
	font-weight: 600;
	padding: 0.75em 1.5em;
	background-color: @color-background;
	outline: none;
	border: 2px solid @main-color;

	&::placeholder {
		opacity: 0.7;
	}
}

.button,
button,
input,
table,
fieldset {
	border-radius: 25em;
}
