@header-height: 4rem;
@max-width: 34rem;
@grid-gap: 2rem;
@grid-columns: auto @max-width auto;
@main-color: #1a1a1a;
@color-background: #fff;

@color-main: #52c083;

.tablet(@rules) {
	@media (max-width: @max-width + @grid-gap * 2) {
		@rules();
	}
}
