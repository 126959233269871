@import '~styles/variables.less';

#root {
	display: grid;
	width: 100%;
	height: 100%;
	grid-template-areas:
		'left  nav   right'
		'left main   right'
		'fbg footer fbg_end';
	grid-template-rows: @header-height 1fr 1fr;
	grid-template-columns: @grid-columns;
	gap: @grid-gap;

	.tablet({
		grid-template-columns: 0 1fr 0;
	});
}

nav {
	grid-area: nav;
}

main {
	grid-area: main;

	:first-child {
		margin-top: 0;
	}
}

footer {
	grid-area: footer;
}
